import React, {useEffect, useState} from 'react';
import { io } from 'socket.io-client';
import axios from "axios";
import './App.css';

function generateTimeSlots(minTime,maxTime,interval) {
    const slots = [];
    for (let hour = minTime; hour <= maxTime; hour++) {
        for (let minutes = 0; minutes < 60; minutes += interval) {
            slots.push({
                hour: minutes === 0 ? `${hour.toString().padStart(2, '0')}` : '',
                minute: `${minutes.toString().padStart(2, '0')}`,
                time: `${hour.toString().padStart(2, '0')}:${minutes
                    .toString()
                    .padStart(2, '0')}`,
            });
        }
    }
    return slots;
}

export default function App() {
    const [barbers, setBarbers] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [workingHours, setWorkingHours] = useState({});


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');

    const today = new Date();
    const minTime = 9;
    const maxTime = 22;
    const interval = 15; // 15 minutes
    const date = today.toISOString().split('T')[0]; // This formats the date as 'YYYY-MM-DD'
    const dayOfWeek = today.toLocaleString('en-US', { weekday: 'long' }); // Get current day of the week

    const timeSlots = generateTimeSlots(minTime,maxTime,interval);
    const [closestTimeSlotIndex, setClosestTimeSlotIndex] = useState(null);


    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    const startOfDayInMinutes = minTime * 60; // Assuming the start of the day is at 9:00 AM
    const currentPositionInMinutes = currentTimeInMinutes - startOfDayInMinutes;

    const rowHeight = 31; // Height per time slot
    const positionInRows = currentPositionInMinutes / interval; // Find the position in terms of rows

    const fetchData = async () => {
        setLoading(true);
        await axios.post('/fetch-calendar', {userId: id})
            .then((response) => {
                setBarbers(response.data.barbers); // Handle the response data
                setAppointments(response.data.appointments)
                setWorkingHours(response.data.workingHours)
            })
            .catch((error) => {
                console.error('Axios error:', error); // Handle any errors
            });
        setLoading(false);
    };

    useEffect(() => {
        //fetchData();
    }, []);



    useEffect(() => {
        const socket = io('https://fervent-lovelace.212-227-171-89.plesk.page');
        //const socket = io('http://192.168.2.14:3001');


        // Listen for newly added users
        socket.on('appointment-updated', (newData) => {
            console.log('trigger')
            setAppointments(newData.appointments);
        });

        return () => {
            socket.disconnect();
        };
    }, []);


    const isWithinWorkingHours = (time, barber) => {
        const workingHour = getWorkingHoursForDay(dayOfWeek)[barber];
        if (!workingHour) {
            return false;
        }

        const [startHour, startMinute] = workingHour.start.split(':').map(Number);
        const [endHour, endMinute] = workingHour.end.split(':').map(Number);
        const [currentHour, currentMinute] = time.split(':').map(Number);

        const startTimeInMinutes = startHour * 60 + startMinute;
        const endTimeInMinutes = endHour * 60 + endMinute;
        const currentTimeInMinutes = currentHour * 60 + currentMinute;

        return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes < endTimeInMinutes;
    };

    const getWorkingHoursForDay = (day) => {
        const filteredHours = {};
        Object.keys(workingHours).forEach((barber) => {
            const hoursForDay = workingHours[barber].find(
                (entry) => entry.day === day
            );
            if (hoursForDay) {
                filteredHours[barber] = hoursForDay;
            }
        });
        return filteredHours;
    };

    const filterAppointmentsByDate = (barber) => {
        if (!appointments[barber]) {
            return [];
        }
        return appointments[barber].filter((appt) => appt.date === date);
    };

    const scrollToCenter = (topPosition) => {
        window.scrollTo({
            top: topPosition - window.innerHeight / 2, // Center the line
            behavior: 'smooth', // Smooth scroll
        });
    };

    const calculateClosestTimeSlotIndex = () => {
        return timeSlots.findIndex((slot) => {
            const [hour, minute] = slot.time.split(':').map(Number);
            const slotTimeInMinutes = hour * 60 + minute;
            return slotTimeInMinutes > currentTimeInMinutes;
        });
    };

    useEffect(() => {
        const updateTimeSlot = () => {
            const newClosestTimeSlotIndex = calculateClosestTimeSlotIndex();
            setClosestTimeSlotIndex(newClosestTimeSlotIndex);

            const topPosition = positionInRows * rowHeight;

            scrollToCenter(topPosition);
        };

        updateTimeSlot();

        const intervalId = setInterval(updateTimeSlot, 60 * 1000); // Every 1 minute

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [barbers, appointments]);


    const renderRow = ( item ) => {
        return (
            <div className="row" key={item.id}>
                {barbers.map((barber) => {
                    const appointment = filterAppointmentsByDate(barber).find(
                        (appt) => appt.start === item.time
                    );

                    if (appointment) {
                        const { duration, client, barberId, date } = appointment;

                        return (
                            <div
                                key={`${barber}-${item.time}`}
                                className="slot"
                                style={{
                                    width: '100%',
                                }}
                            >
                                <div
                                    className="appointment"
                                    style={{
                                        height: (duration / interval) * 31 -1, // Adjust height for duration
                                    }}
                                >
                                    <span>{client}</span>
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div
                            key={`${barber}-${item.time}`}
                            className={`slot ${
                                isWithinWorkingHours(item.time, barber) ? 'workingHours' : ''
                            }`}
                            style={{ width: '100%' }}
                        />
                    );
                })}
            </div>
        );
    };

    return (loading ? "loading" : (
            <div className="container">
                <div className="headerRow">
                    <div className="timeColumnMain">
                        <div className="timeHeader">
                            <span className="headerText">Time</span>
                        </div>
                        {timeSlots.map((item, index) => (
                            <div className="row" key={item.time}>
                                <div
                                    className={`timeColumn ${
                                        (index + 1) % 4 === 0 ? 'timeColumnLast' : ''
                                    }`}
                                >
                                    <span className="hourText">{item.hour}</span>
                                </div>
                                <div
                                    className={`minuteColumn`}
                                >
                                    <span className="minuteText">{item.minute}</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="test">
                        <div className="barberHeaderRow">
                            {barbers.map((barber, index) => (
                                <div
                                    key={index}
                                    className="barberHeader"
                                    style={{width: '100%'}}
                                >
                                    <span className="headerText">{barber}</span>
                                </div>
                            ))}
                        </div>
                        <div className="timeSlots">
                            {timeSlots.map((el) => renderRow(el))}
                        </div>
                    </div>
                </div>

                {closestTimeSlotIndex >= 0 && (
                    <div
                        className="currentTimeLine"
                        style={{
                            top: positionInRows * rowHeight + 60,
                        }}
                    />
                )}
            </div>
        )
    );
}
